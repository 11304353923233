@import '../../../styles/variables.scss';

.tv-assignment-rating {
  &__container {
    display: flex;
  }
  &__text-grade {
    font-size: 12px;
    color: #252525;
  }
}
