@import '../../../styles/variables.scss';

.tv-loading {
  &__loader-container {
    min-width: 75px;
    width: 100%;
    height: 4px;
    background-color: $BLUE-4;
  }
  &__loader-cursor {
    width: 50%;
    height: 100%;
    background-color: $BLUE-10;
    animation: move-up 1.5s infinite linear alternate;
  }
  @keyframes move-up {
    100% {
      transform: translateX(100%);
    }
    50% {
      transform: translateX(50%);
    }
    0% {
      transform: translateX(0%);
    }
  }
}
