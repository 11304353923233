.tv-select {
  &__container {
    position: relative;
    min-width: 80px;
    height: 48px;
    padding: 0;
    width: 100%;
  }
  &__display {
    width: 100%;
    display: flex;
    vertical-align: center;
    font-size: 16px;
    line-height: 48px;
    height: 100%;
    padding-left: 16px;
    border: 1px solid #e2e2e2 !important;
    border-radius: 4px;
    outline: none;
    &--default {
      color: #555454;
    }
    &-icon {
      position: absolute;
      top: 0;
      right: 0;
      height: 48px;
      line-height: 48px;
      text-align: center;
      width: 48px;
      cursor: pointer;
    }
  }
  &__drop-down-container {
    display: none;
    height: 150px;
    width: 100%;
    position: absolute;
    top: 48px;
    list-style: none;
    cursor: pointer;
    text-decoration: none;
    border-radius: 4px;
    box-shadow: 0 1px 5px #ccc;
    overflow-y: scroll;
    background-color: #ffffff;
    z-index: 20;
    padding: 0;
    margin: 0;
    // Firefox scroll
    -moz-appearance: none !important;
    scrollbar-width: thin;
    scrollbar-color: #bbbbbb;
    // IE Scroll
    scrollbar-face-color: #bbbbbb;
    scrollbar-shadow-color: #ffffff;
    scrollbar-highlight-color: #ffffff;
    &::-webkit-scrollbar {
      width: 8px;
      margin-top: 7px;
    }
    &::-webkit-scrollbar-track {
      background-color: #ffffff;
      margin-top: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #bbbbbb;
      border-radius: 8px;
      height: #{'min(15px,10vh)'};
      width: 8px;
    }
  }
  &__drop-down--open {
    display: flex;
    flex-direction: column;
  }
  &__drop-down-item {
    font-size: 16px;
    line-height: 20px;
    padding: 14px 15px;
    &:hover {
      background-color: #f1f1f1;
    }
  }
}
