@import '../../../styles/variables.scss';

.processing-personal-data {
  p {
    font-size: 18px;
    a {
      color: $BLUE_10;
      text-decoration: underline;
      &:focus-visible {
        outline: 2px solid $OUTLINE_COLOR;
        outline-offset: 1px;
      }
    }
  }
}
