@import '../../../styles/variables.scss';

.tv-collapse {
  &__wrapper {
    display: flex;
    background-color: #f1f3f4;
    padding: 11.5px 15px;
  }
  &__head {
    width: 97%;
  }

  &__list {
    flex-direction: column;
    justify-content: center;

    &-item {
      border-radius: 5px;
      margin: 4px 0;
      padding: 24px;
      background: #f1f3f4;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
    }
  }

  &__heading {
    font: 300 rem-calc(18) / rem-calc(28) Campton;
    letter-spacing: -0.2px;
  }

  &__item-menu {
    width: 3%;
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 7px;

    i {
      position: absolute;
      transition-duration: 0.7s;
    }
  }

  &__title {
    color: #353535;
    font: 500 rem-calc(24) / rem-calc(32) $PRIMARY_FONT;
    letter-spacing: -0.2px;
  }

  &__content {
    $self: &;
    max-height: 0;
    overflow: hidden;
    // transition-duration: 0.7s;
    transition-property: max-height;
    position: relative;

    &--opened {
      max-height: 100rem;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.3s;
      transition-property: max-height;

      #{ $self }-inner {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 0.2s;
        transition-timing-function: ease-in-out;
        transition-duration: 0.2s;
        transition-property: opacity, transform;
      }
    }

    &-inner {
      opacity: 0;
      transform: translateY(-1rem);
      transition-timing-function: linear, ease;
      transition-duration: 0.1s;
      transition-property: opacity, transform;
      transition-delay: 0.5s;
      margin-top: 15px;
    }
  }

  &__icon {
    transform: rotate(180deg);
    transition-duration: 0.5s;
  }
}
