@import '../../../styles/variables.scss';

.tv-person-data-policy {
  &.single {
    font-size: 14px;
    padding: 0;
    flex: unset;
    flex-direction: unset;
    .tv-person-data-policy__heading1 {
      font-weight: normal;
      line-height: 18px;
      color: #000000;
      letter-spacing: -0.2px;
      height: 109px;
      padding-top: 38px;
    }
    .tv-person-data-policy__page-wrap {
      .tv-person-data-policy__page {
        max-width: 60vw;

        @media (max-width: $breakpoint-tablet) {
          max-width: 100%;
        }

        &:first-child {
          margin-bottom: 5rem;
        }
        .tv-person-data-policy__heading2 {
          font-size: 14px;
          color: #000000;
          line-height: 17.71px;
          margin-top: 24px;
        }
        .tv-person-data-policy__paragraph {
          font-size: 14px;
          color: #000000;
          line-height: 17.71px;
          margin-top: 24px;
        }
        .tab-panel-section__sub {
          padding-left: 10px;
        }
      }
    }
  }
}
