@import "../../../styles/variables.scss";

iframe {
  display: none;
}

.tv-toggle {
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  height: 24px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 64px;
  &__container {
    align-items: center;
    background-color: white;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    height: 100%;
    justify-content: center;
  }

  &__label {
    cursor: pointer;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 24px;
  }

  &__input {
    opacity: 0;

    &:checked {
      ~ .tv-toggle__text {
        background: $BLUE-10;
        overflow: hidden;

        &:before {
          opacity: 0;
          transform: translateX(200%);
        }

        &:after {
          opacity: 1;
          transform: translateX(0);
        }
      }

      ~ .tv-toggle__handle {
        transform: translateX(40px);
      }
    }
  }

  &__text {
    background-color: #707070;
    border-radius: 36px;
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    height: 24px;
    margin: 0;
    width: 64px;

    &:before,
    &:after {
      color: white;
      font-size: 12px;
      font-weight: 500;
      line-height: 25px;
      transition: all 0.2s ease-in-out;
      bottom: 1px;
      transition-property: transform;
    }

    &:before {
      content: attr(data-off);
      position: relative;
      right: -34px;
      transform: translateX(0);
    }

    &:after {
      color: white;
      content: attr(data-on);
      position: relative;
      left: -1px;
      opacity: 0;
      transform: translateX(-200%);
    }
    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      outline-offset: -2px;
    }
  }

  &__handle {
    background-color: white;
    border-radius: 36px;
    display: block;
    height: 20px;
    margin: 2px;
    padding: 0;
    position: relative;
    top: -24px;
    transition: all 0.2s ease-in-out;
    transition-property: transform;
    width: 20px;
  }

  &__input:disabled ~ &__text {
    background-color: #7e7d7d;
    &:before,
    &:after {
      color: #e6e4e4;
    }
  }
  &__input:disabled ~ &__handle {
    background-color: #d3d3d3;
  }

  &--large {
    height: 30px;
    width: 70px;

    .tv-toggle_input {
      &:checked {
        ~ .tv-togglehandle {
          transform: translateX(40px);
        }
      }
    }

    .tv-toggle__text {
      height: 30px;
      width: 70px;
      overflow: hidden;

      &:before,
      &:after {
        font-size: 14px;
        line-height: 30px;
        position: absolute;
      }

      &:before {
        right: 10px;
      }

      &:after {
        left: 12px;
      }
    }

    .tv-togglehandle {
      height: 26px;
      width: 26px;
    }
  }

  &--no-text {
    .tv-toggle__text {
      overflow: hidden;
      &:before,
      &:after {
        display: none;
      }
    }
  }
  &--inactive {
    input.tv-toggle__input {
      cursor: not-allowed !important;
    }
  }
  &--success {
    .tv-toggle_input {
      &:checked {
        ~ .tv-toggle__text {
          background: #5ed37a;
          overflow: hidden;
        }
      }
    }
  }

  &__inactive-message {
    visibility: hidden;
    background: #333;
    padding: 10px 15px;
    width: 300px;
    position: absolute;
    z-index: 999;

    color: white;
    top: 35px;
    left: -55px;
    border-radius: 8px;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2s;

    @media screen and (max-width: 768px) {
      top: 35px;
      left: -55px;
    }

    &::before {
      background-color: transparent;
      content: " ";
      display: block;
      position: absolute;
      top: -15px;
      left: 75px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 10px 15px 10px 0;
      border-color: transparent #333 transparent transparent;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      transform: rotateZ(90deg);
    }
    .tv-toggle--inactive:hover ~ &,
    .tv-toggle--inactive:focus ~ &,
    .tv-toggle--inactive:active ~ & {
      visibility: visible;
      transform: scale(1) translateY(0);
      z-index: 999;
      transition-delay: 0.2s;
    }

    & > a {
      color: #ffa500;
    }
  }
}
.pulsate::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: pulse 1s ease infinite;
  border-radius: 16px;
  border: 5px double #ff6666;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
    border-radius: 16px;
  }
  20% {
    transform: scale(1.1);
    opacity: 0.8;
    border-radius: 16px;
  }
  40% {
    transform: scale(1.2);
    opacity: 0.6;
    border-radius: 17px;
  }
  60% {
    transform: scale(1.3);
    opacity: 0.4;
    border-radius: 18px;
  }
  80% {
    transform: scale(1.4);
    opacity: 0.2;
    border-radius: 19px;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
    border-radius: 20px;
  }
}

.tv-toggle-tooltip {
  position: relative;
  cursor: not-allowed !important;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    &:hover::before,
    &:focus::before,
    &:hover::after,
    &:focus::after {
      opacity: 0;
    }
    &::after,
    &::before {
      display: none !important;
    }
  }

  &::before,
  &::after {
    left: 50%;
    opacity: 0;
    position: absolute;
    z-index: -100;
  }
  &::after {
    background-color: #333;
    border-radius: 8px;
    font-size: 14px;
    color: white;
    opacity: 0;
    display: block;
    padding: 10px 15px;
    width: 300px;
    position: absolute;
    text-align: center;
  }

  &::before {
    background-color: #333;
    content: " ";
    display: block;
    opacity: 0;
    position: absolute;
    width: 15px;
    height: 15px;
  }

  &:hover::before,
  &:focus::before,
  &:hover::after,
  &:focus::after {
    opacity: 1;
    transform: scale(1) translateY(0);
    z-index: 999;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
  }

  &.bottom::after {
    content: attr(data-tip);
    bottom: 0;
    left: 50%;
    transform: translate(-50%, calc(100% + 10px));
  }

  &.bottom::before {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, calc(100% + 5px)) rotate(45deg);
  }
}
