.tv-assignment-list {
  &__container {
    width: 100%;
  }
  &__wrapper {
    margin: 0px 20px 0 16px;
    display: flex;
  }

  &__assignments {
    margin-bottom: 40px;
    margin-top: 6px;
  }
  &__side-panel {
    @supports (display: flex) {
      display: flex;
      margin-right: #{'clamp(16px,1.5vw, 32px)'};
      width: #{'max(22.3vw, 320px)'};
    }
    margin-right: 16px;
    min-width: 320px;
    background-color: #fff;
    display: -ms-flexbox;
    -ms-flex-preferred-size: #{'max(22.3vw, 320px)'};
    width: 22.3vw;
  }
  @media (max-width: 1024px) {
    &__side-panel {
      width: 100%;
      flex-direction: column;
      .filter-panel__search {
        input {
          width: 100%;
        }
        width: 100%;
      }
    }
    &__assignments {
      margin-bottom: 0;
      display: flex;
      margin-top: 0px;
    }
    &__wrapper {
      margin: 0;
      flex-direction: column;
    }
  }
}
