@import "../../../styles/variables.scss";

.tv-timepicker {
  &__container {
    position: relative;
    min-width: 80px;
    height: 48px;
    &.active {
      & .tv-timepicker__select-panel {
        height: 150px;
        display: block;
        animation: slidepanel 0.25s;
        @keyframes slidepanel {
          0% {
            height: 0px;
          }
          100% {
            height: 150px;
          }
        }
      }
    }

    & ul {
      & > li {
        &:hover {
          background-color: #f1f1f1;
        }
      }
      // Firefox scroll
      -moz-appearance: none !important;
      scrollbar-width: thin;
      scrollbar-color: #bbbbbb;
      // IE Scroll
      scrollbar-face-color: #bbbbbb;
      scrollbar-shadow-color: #ffffff;
      scrollbar-highlight-color: #ffffff;
      &::-webkit-scrollbar {
        width: 8px;
        margin-top: 7px;
      }
      &::-webkit-scrollbar-track {
        background-color: #ffffff;
        margin-top: 7px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #bbbbbb;
        border-radius: 8px;
        height: #{'min(154px,15%)'};
        width: 8px;
      }
    }
    .tv-timepicker__input {
      &:focus-visible {
        outline: 2px solid $OUTLINE_COLOR;
        border-radius: 4px;
      }
    }
  }
  &__select-option {
    height: inherit;
    width: 42px;
    padding: 22px 22px;
    position: absolute;
    right: 0;
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      right: 16px;
      top: 20px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;

      border-top: 5px solid #000;
    }
  }

  &__select-panel {
    display: none;
    height: 150px;
    width: 100%;
    position: absolute;
    top: 48px;
    list-style: none;
    cursor: pointer;
    text-decoration: none;
    border-radius: 4px;
    box-shadow: 0 1px 5px #ccc;
    overflow-y: scroll;
    background-color: #ffffff;
    z-index: 20;
    padding: 0;
    & li {
      font-size: 16px;
      line-height: 20px;
      padding: 14px 15px;
      &:focus-visible {
        outline: 2px solid $OUTLINE_COLOR;
        outline-offset: -3px;
        border-radius: 4px;
      }
    }
  }
  &__input {
    position: absolute;
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    height: 100%;
    padding-left: 16px;
    border: 1px solid #e2e2e2;
    outline: none;
    &:focus {
      outline: none;
    }
  }
}

body {
  .panel-overlay {
    display: block;
    position: absolute;
    overflow: hidden;
    z-index: 2;
    top: 0;
  }
}
