.skip-links-container {
    position: fixed;
    top: 10px;
    left: 190px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid;
    transform: translateX(-100%);
    animation: slide-in 0.5s forwards;
}

.skip-link {
    display: inline-block;
    padding: 5px 10px;
    background-color: #333;
    color: #fff;
    padding: 10px 20px !important;
    &:focus-visible {
        outline: 2px solid white;
        outline-offset: -4px;
        border-radius: 4px;
    }
}

.skip-link:hover {
    color: #fff;
}
