// Blue Shades
$BLUE-10: #014a8e;
$BLUE-9: #1a5c98;
$BLUE-8: #326ea2;
$BLUE-7: #4d80af;
$BLUE-6: #6692b9;
$BLUE-5: #80a4c6;
$BLUE-4: #99b7d1;
$BLUE-3: #b3c9de;
$BLUE-2: #cddbe8;
$BLUE-1: #e6edf5;

// Gray Shades
$GRAY-10: #5d6c73;
$GRAY-9: #6d7a82;
$GRAY-8: #7e898f;
$GRAY-7: #8d989e;
$GRAY-6: #9ea5ad;
$GRAY-5: #adb5b8;
$GRAY-4: #bfc4c8;
$GRAY-3: #ced3d6;
$GRAY-2: #dee2e3;
$GRAY-1: #eff0f2;

// Orange Shades
$ORANGE-10: #f38025;
$ORANGE-9: #f58c3b;
$ORANGE-8: #f69a51;
$ORANGE-7: #f7a667;
$ORANGE-6: #f8b37c;
$ORANGE-5: #f9be94;
$ORANGE-4: #faccaa;
$ORANGE-3: #fbd9be;
$ORANGE-2: #fce7d4;
$ORANGE-1: #fcf2e9;

$PURPLE_COLOR: #231950;
$TEXT_COLOR: #323232;
$TEXT_INACTIVE_COLOR: #969696;
$PURPLE_BLOOD_COLOR: #e1e6ff;
$BACKGROUND_COLOR: #fff;
$SIDE_DRAWER_BACK_COLOR: #fbfbfb;
$NAV_BAR_HOVER_COLOR: #fafafa;
$NAV_BAR_TEXT_COLOR: #313131;
$WHITE_COLOR: #fff;
$BLACK_COLOR: #000;
$TITLE_COLOR: #f9f9f9;
$TEXT_TITLE_COLOR: #353535;
$LIGHT_PINK_COLOR: #ffd2cd;
$PINK_COLOR: #ff9ba0;
$PURPLE_BLUE_COLOR: #6478e6;

$DARK_GRAY_COLOR: #a9a9a9;
$DARK_GRAY_COLOR-1: #f7f7f7;
$DARK_GRAY_COLOR-2: #c4c4c4;
$FONT_LIGHT_GRAY_COLOR: #74737b;
$COMMON_TEXT_COLOR: #252525;
$BORDER_COLOR: #e0e0e0;
$BGCOLOR-ICON: #d1d1d1;

// fonts
$PRIMARY_FONT: 'Source Sans Pro', 'Source Sans Pro';
$SECONDARY_FONT: 'Open Sans', 'Open Sans';

$SCROLLBAR_WIDTH: 10px;
$LIGHT_GRAY_COLOR: #d8d8d8;

// Status Color
// $COLOR_STATUS_AVAILABLE: #ff9b4e;
$COLOR_STATUS_AVAILABLE: #c25706;
// $COLOR_STATUS_ACCEPTED: #6478e6;
$COLOR_STATUS_ACCEPTED: #5f6ebe;
$COLOR_STATUS_REJECTED: #e94242;
// $COLOR_STATUS_FULLILLED: #18ad79;
$COLOR_STATUS_FULLILLED: #048557;
$COLOR_STATUS_CANCELLED: #74737b;
// $STATUS_REPORTED_COLOR: #b76ed1;
$STATUS_REPORTED_COLOR: #9e5cb6;
$STATUS_ONGOING_COLOR: #be3c09;

$COLOR_STATUS_SUBMITTED: #008000;
$COLOR_STATUS_WORKING: #fbbcdb;
$COLOR_STATUS_DONE: #ffa500;
$COLOR_STATUS_LOSTED: #696969;
$COLOR_STATUS_CUSTOM: #9c69ad;

// Deviation Status Color
$COLOR_STATUS_NEWREGISTERED: #ffa500;
$COLOR_STATUS_UNDERINVESTIGATE: #046fe2;
$COLOR_STATUS_COMPLETED: #008000;

// Navigation
$COLOR_NAVIGATION_ITEM: #8f8f8f;
$COLOR_NAVIGATION_ACTIVE_ITEM: $BLUE-10;

// Feedback
$COLOR_REPORT_STATUS: #414141;

// Sub-Satus Color
$COLOR_SUBSTATUS_SEARCHING_FOR_INTERPRETER: rgba(255, 155, 78, 0.32);
$COLOR_SUBSTATUS_INTERPRETER_ACCEPTED: rgba(100, 120, 230, 0.32);
$COLOR_SUBSTATUS_ORDER_IN_PROGRESS: rgba(100, 120, 230, 0.32);
$COLOR_SUBSTATUS_ASSIGNING_INTERPRETER: rgba(100, 120, 230, 0.32);
$COLOR_SUBSTATUS_AWAITING_TIME_REPORT: rgba(24, 173, 121, 0.32);
$COLOR_SUBSTATUS_TIME_REPORT_RECEIVED: rgba(24, 173, 121, 0.32);
$COLOR_SUBSTATUS_DEVIATION_REGISTERED: #74737b;
$COLOR_SUBSTATUS_ORDER_CANCELLED: rgba(233, 66, 66, 0.32);
$COLOR_SUBSTATUS_ORDER_REJECTED: rgba(233, 66, 66, 0.32);
$COLOR_SUBSTATUS_UNEXPECTED_EVENT_OCCURED: rgba(233, 66, 66, 0.32);
$COLOR_SUBSTATUS_UNKNOWN_STATUS: #fff;

// Label Color
$COLOR_TEXT_LABEL: #f4d0dd;

// Previous Colors
$COMMON_BORDER: 1px solid #e0e0e0;
$NAVIGATION_COLOR: #707070;
$SECONDARY_COLOR: #322878;
// $STATUS_INQUIRY_COLOR: #ff9b4e;
$STATUS_INQUIRY_COLOR: #c25706;
// $STATUS_ACCEPTED_COLOR: #6478e6;
$STATUS_ACCEPTED_COLOR: #5f6ebe;
// $STATUS_DONE_COLOR: #18ad79;
$STATUS_DONE_COLOR: #048557;
$STATUS_CANCELLED_COLOR: #74737b;
$breakpoint-tablet: 768px;

$OUTLINE_COLOR: #be5a08;
