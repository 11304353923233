@import './styles/fonts.scss';
@import './styles/variables.scss';
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $PRIMARY_FONT;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
  font-family: $PRIMARY_FONT;
}

.tv-mobile {
  &--show {
    display: none !important;
  }
  @media (max-width: $breakpoint-tablet) {
    &--hide {
      display: none !important;
    }
    &--show {
      display: initial !important;
    }
  }
}

.tv-tab {
  &--show {
    display: none !important;
  }
  @media (max-width: 1024px) {
    &--hide {
      display: none !important;
    }
    &--show {
      display: initial !important;
    }
  }
}
