@import '../../../styles/variables.scss';

.tv-change-password-form {
  &__container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 502px;
    padding-top: 10px;
    min-height: 414px;
  }

  @media (max-width: $breakpoint-tablet) {
    &__container {
      width: calc(100vw - 32px) !important;
      margin: 0px;
      box-shadow: none;
      border: none;
      padding-top: 0px;
    }
  }
}
