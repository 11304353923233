@import "../../../styles/variables.scss";

.tv-assignment-navigator {
  &__container {
    display: flex;
    justify-content: space-between;
  }
  &__content {
    display: flex;
    align-items: center;
    span {
      font-size: 12px;
      color: #74737b;
    }
    &:focus-within:has(:focus-visible) {
      outline: 2px solid $OUTLINE_COLOR;
      border-radius: 6px;
      outline-offset: -4px;
    }
    button {
      &:focus-visible {
        outline: none;
      }
      i {
        color: #74737b;
      }
    }
  }
}
