@import '../../../styles/variables.scss';

.tv-sidebar-assignment-list {
  width: 100%;
  min-width: 320px;
  height: 68vh;
  border-radius: 4px;
  width: 100%;
  border: 1px solid $BORDER_COLOR;
  display: flex;
  flex-direction: column;
  background: #fff;
  z-index: 1;

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 13px 16px;
    border-bottom: 1px solid $BORDER_COLOR;
    position: relative;
    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      color: $COMMON_TEXT_COLOR;
      text-transform: capitalize;
    }
    i.fa-times {
      position: absolute;
      top: 12px;
      right: 17px;
      -webkit-text-stroke: 1.5px #fff;
      font-size: 24px;
      &:focus-visible {
        outline: 2px solid $OUTLINE_COLOR;
        border-radius: 4px;
      }
    }
    .month-week-single-assignment {
      &:focus-visible {
        &::after {
          content: '';
          position: absolute;
          top: 15px;
          right: 5px;
          border: 2px solid $OUTLINE_COLOR;
          width: 10%;
          height: 70%;
          border-radius: 4px;
          @media (min-width: 768px) {
            top: 8px;
            right: 8px;
          }
        }
      }
    }
  }
  &__content {
    &-text {
      padding: 16px;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: $COMMON_TEXT_COLOR;
    }
    &-timeline {
      overflow-y: scroll;
      position: relative;
      min-height: auto;
      height: calc(67vh - 190px);
      scroll-behavior: smooth;
      display: flex;
      flex-direction: column;
      margin: auto #{'max(16px, min(1vw, 33px))'} auto auto;

      &::-webkit-scrollbar {
        width: 4px;
        margin-top: 7px;
      }
      &::-webkit-scrollbar-track {
        background-color: #ffffff;
        margin-top: 7px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #bbbbbb;
        border-radius: 8px;
        height: #{'min(154px,15vh)'};
        width: 4px;
      }
      &-container {
        z-index: 4;
      }
      &-frame {
        display: flex;
        border-top: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        height: 128px;
        hr {
          margin-top: 8px;
        }
        &-time {
          font-weight: bold;
          font-size: 8px;
          line-height: 16px;
          height: 127px;

          color: #707070;
          border-right: 1px solid #e0e0e0;

          padding: 0 12px 0 8px;
          width: 38px;
          position: relative;
          left: -2px;
          top: -5px;
          z-index: 1;
          background-color: #fff;
        }
      }
      &__curent-time {
        border-color: $BLUE-10;
        position: absolute;
        padding: 0;
        z-index: 7;
        width: calc(100% - 38px);
        margin-left: 38px;
        border-width: 2px;
        &:before {
          content: '\2688';
          color: $BLUE-10;
          top: -12px;
          left: -8px;
          position: absolute;
        }
      }
    }
    &-availability {
      &--default {
        width: calc(100% - 39px);
        position: absolute;
        top: 0;
        z-index: 2;
        left: 37px;
      }
      &-container {
        z-index: 3;
      }
      &-slot {
        background-repeat: repeat-y;
        position: absolute;
        width: calc(100% - 39px);
        left: 37px;
        position: absolute;
        font-size: 11px;
      }
    }
  }
  &__close-button {
    padding: 14px;
    align-items: center;
    margin: 16px 16px;
  }

  @media (max-width: 768px) {
    min-height: auto;
    min-width: auto;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: #fff;
    left: 0;
    top: 0;
    &__header {
      border-bottom: none;
      justify-content: center;
      padding-top: 20px;
      padding-bottom: 10px;
      &-title {
        text-align: center;
      }
      i.fa-times {
        position: absolute;
        top: 21px;
        right: 17px;
        -webkit-text-stroke: 1.5px #fff;
        font-size: 24px;
      }
    }
    &__content {
      &-timeline {
        height: calc(100vh - 140px);
      }
    }
    &__close-button {
      margin: auto 16px 16px;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    min-height: auto;
    min-width: auto;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: #fff;
    left: 0;
    top: 0;
    &__header {
      border-bottom: none;
      justify-content: center;
      padding-top: 20px;
      padding-bottom: 10px;
      &-title {
        text-align: center;
      }
      i.fa-times {
        position: absolute;
        top: 21px;
        right: 17px;
        -webkit-text-stroke: 1.5px #fff;
        font-size: 24px;
      }
    }
    &__content {
      &-timeline {
        height: calc(100vh - 160px);
      }
    }
    &__close-button {
      margin: auto 16px 16px;
    }
  }
}
