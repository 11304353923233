@import '../../../styles/variables.scss';

.tv-avatar {
  display: inline;
  position: relative;
  cursor: pointer;

  &__img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      border-radius: 100%;
      outline-offset: 2px;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    &__img {
      width: 26px;
      height: 26px;
    }
  }
}
