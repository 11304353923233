@import '../../../styles/variables.scss';

.tv-navigationlink {
  .nav-item,
  .page {
    margin: 3px 18px;
    padding: 5px;
    color: #74737b;
    font-weight: 500;
    font-size: 16px;
    &:hover {
      color: $BLUE-10;
      text-decoration: underline;
    }
    &--active {
      color: $BLUE-10;
      text-decoration: underline;
      font-weight: 600;
    }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      margin: 3px 5px;
    }
  }
}
