@import '../../../styles/variables.scss';

.tv-month-day {
  border: 1px solid #e0e0e0;
  @supports (display: flex) {
    width: unquote('max(147px, 11.2vw)');
    height: unquote('max(121px, 15.6vh)');
  }
  width: 11.2vw;
  height: 11.2vw;
  min-height: 85px;
  padding: 8px;
  vertical-align: top;

  &__semester-container {
    //color: $NAVIGATION_COLOR; change due to color ration issue from lighthouse
    color: #706464;
    display: flex;
    flex-direction: column;
    padding: 7px 8px;
    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      border-radius: 2px;
      outline-offset: -1px;
    }

    &-header {
      color: #706464;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      text-transform: capitalize;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 4px;
      margin-top: 16px;

      &--name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &-sub-container {
      color: #706464;
      border-top: 1px solid #707070;
      display: flex;
      flex-direction: column;
      font-weight: 500;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 0.5px;
      padding-top: 8px;

      span {
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .icon-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 8px;
      }
    }
  }

  &__date-wrapper {
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    color: #000;
    font-size: 13px;
    margin-bottom: 8px;
    font-weight: 500;
  }

  &__zIndex {
    z-index: 20;
  }

  &__week-number {
    color: #706464;
    @media (max-width: 768px) {
      display: none;
    }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      display: none;
    }
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 48px);
    height: auto;
    padding: 0px;
    border: none;
    &__date-wrapper {
      color: #66666b;
      font-size: 11px;
      font-weight: 400;
    }
  }
  @media (min-width: 768px) and (orientation: landscape) {
    .d-md-block-none-landscape {
      // display: none !important;
    }
  }
  @media (min-width: 768px) and (orientation: portrait) {
    .d-md-block-none-portrait {
      display: none !important;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 48px);
    height: auto;
    padding: 0px;
    border: none;
    &__date-wrapper {
      color: #66666b;
      font-size: 11px;
      font-weight: 400;
    }
  }
}
