@import '../../../styles/variables.scss';

.tv-terms-and-condition {
  &__container {
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-family: $PRIMARY_FONT;
    font-size: 28px;
    line-height: 35px;
    color: #252525;
    margin-bottom: 32px;
  }
  &__tab {
    margin-bottom: 32px;
    @media (max-width: 378px) {
      .tv-tab_button_row__container {
        width: 100%;
        overflow: overlay;
        .tv-tab_button {
          padding: 14px 3px;
          width: 50%;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
        }
        padding: 0 0;
      }
    }
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    &__title {
      display: none;
    }
  }
}
