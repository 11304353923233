@import "../../../styles/variables.scss";

.tv-calendar-nav {
  .react-datepicker {
    font-family: Circular Std;
    min-width: 320px;
    min-height: 273px;
    border: 1px solid #e0e0e0;
    color: #252525;
    // filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.16));
    &__triangle {
      display: none;
    }
    &-popper[data-placement^="bottom"] {
      margin-top: 4px;
    }

    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      margin: 16px;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 4px;
    }

    &__current-month {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-transform: capitalize;
    }

    &__navigation {
      height: 48px;
      width: 48px;
      top: 16px;
      &:focus {
        outline: none;
      }
      &--next {
        right: 16px;
        &:focus-visible {
          &::after {
            content: "";
            position: absolute;
            top: 15%;
            left: 0;
            border: 2px solid $OUTLINE_COLOR;
            border-radius: 4px;
            width: 75%;
            height: 75%;
          }
        }
      }
      &--previous {
        left: 16px;
        &:focus-visible {
          &::after {
            content: "";
            position: absolute;
            top: 15%;
            left: 25%;
            border: 2px solid $OUTLINE_COLOR;
            border-radius: 4px;
            width: 75%;
            height: 75%;
          }
        }
      }
      &-icon {
        top: 5px;
        border-color: #252525;
        border-width: 2px 2px 0 0;
        top: 6px;
        width: 9px;
        &:before {
          border-color: #252525;
          border-width: 2px 2px 0 0;
        }
      }
    }

    &__month-container {
      width: 100%;
    }

    &__day {
      width: 40px;
      height: 40px;
      font-weight: 500;
      font-size: 16px;
      color: #252525;
      &:focus-visible {
        outline: 2px solid $OUTLINE_COLOR;
      }

      line-height: 40px;
      margin: 0;
      border-radius: 4px;
      &--selected,
      &--in-range,
      &--in-selecting-range {
        background-color: $BLUE-10;
        color: #fff !important;
        &:hover {
          background-color: $BLUE-10;
          color: #fff !important;
        }
      }
      &--today {
        background-color: #fff;
        color: $BLUE-10 !important;
      }

      &--outside-month {
        color: #555454;
      }
      &--keyboard-selected {
        background-color: #fff;
        &:hover {
          background-color: #f1f3f4;
        }
      }
      &--disabled {
        color: #d6d6d6;
      }
      &--range-start,
      &--range-end {
        background-color: $BLUE-10;
      }
      &-name {
        display: none;
      }
    }
    &__input-container > input {
      padding: 14px 16px;
      height: 48px;
      font-size: 16px;
      line-height: 20px;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 4px;
      &:focus {
        outline: none;
      }
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
}
