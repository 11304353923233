@import '../../../styles/variables.scss';

.release-news {
  &__container {
    padding: 0 32px;
    width: unquote('min(900px, 80%)');
    margin: 32px auto;
    display: flex;
    flex-direction: column;
    font-family: $PRIMARY_FONT !important;
    @media screen and (max-width: 768px) {
      padding: 0 !important;
      width: 100% !important;
      margin: 32px 0;
    }
    & .page {
      &-wrapper {
        display: flex;
        height: calc(90vh - 94px);
        flex-direction: column;
        padding-bottom: 17px;
        margin-bottom: 20px;
        margin-top: 5%;
        margin-left: 5%;
        margin-right: 5%;
        overflow: hidden;
        // & > div > div:nth-child(2) {
        //   display: none;
        // }
      }
      &-title {
        font-style: normal;
        font-size: 28px;
        line-height: 35px;
        font-weight: 600;
        letter-spacing: 0;
        margin: 0px 0 0 0;
      }
    }
    .icon {
      font-size: 48px;
      line-height: 30px;
      padding-right: 16px;
      @media screen and (max-width: 768px) {
        text-align: left;
        margin-bottom: 5px;
      }
      p {
        font-size: 16px;
        line-height: 150%;
      }
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      border-radius: 4px;
      border-bottom: 1px solid #e0e0e0;
      padding: 40px 0;

      p {
        font-size: 16px;
        line-height: 150%;
        font-family: $SECONDARY_FONT;

        a{
          font-family: $PRIMARY_FONT;
          color: #014a8e;
        }

        b {
          font-family: $SECONDARY_FONT;
        }
      }
    }

    .container-styled {
      padding: 15px;
    }
    .divder {
      border-bottom: 1px solid #e0e0e0;
    }

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      width: 100%;

      line-height: 24px;
      letter-spacing: 0.2px;
      padding: 8px 0 24px;
      color: #252525;
    }

    .date {
      font-family: $SECONDARY_FONT;
      padding-top: 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;

      letter-spacing: 0.2px;

      color: #252525;

      opacity: 0.6;
    }
  }
}
.status-label-wrapper {
  color: #fff;
  border-radius: 4px;
  text-transform: capitalize;
  padding: 6px 8px;
  letter-spacing: 0.5px;
  font-family: $SECONDARY_FONT;
}

.label-background {
  &_Rapporterad {
    background: $STATUS_REPORTED_COLOR;
  }
  &_Accepterad {
    background: $COLOR_STATUS_ACCEPTED;
  }
  &_Utförd {
    background: $COLOR_STATUS_FULLILLED;
  }
  &_Förfrågan {
    background: $COLOR_STATUS_AVAILABLE;
  }
  &_Avbokad {
    background: $COLOR_STATUS_CANCELLED;
  }
}
.tv-title-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  font-family: $PRIMARY_FONT;
}

.title-text-list {
  li {
    font-family: $SECONDARY_FONT;
    
    u {
      font-family: $SECONDARY_FONT;
    }
  }
}

.nobr-text {
  font-family: $SECONDARY_FONT;
}
