@import '../../../styles/variables.scss';

.tv-assignment-request-list {
  &__container {
    width: 100%;
    .availableRequest-results-empty {
      display: flex;
      justify-content: center;
      ext-max-lenghtext-max-lenght-align: center;
      padding-top: 20px;
      padding-bottom: 10%;
    }
    .tv-assignment-item__row {
      &--type {
        flex-grow: 5;
      }
      &--number {
        width: 8%;
      }
      &--pay {
        width: 7%;
      }
    }
    .tv-assignment-request-list-header__item-type {
      flex-grow: 5;
    }
    .order-1 {
      padding-left: 15px;
    }
    .order-2 {
      padding-left: 16px;
    }
  }
  &-bg-white {
    background-color: white;
  }
  &-title {
    font-weight: 500;
    font-size: 24px;
    color: #252525;
    margin-bottom: 24px;
  }
  &-header {
    display: flex;
    width: 100%;
    margin: 0;
    hr {
      margin: 0 15px 0 0;
    }
    &__item {
      flex-grow: 1;
      width: 10%;
      font-size: 14px;
      line-height: 18px;
      color: #74737b;
      text-wrap: nowrap;
      &-type {
        padding-left: 18px;
      }
      &-pay {
        width: 8% !important;
      }
      &-req-response {
        width: 17%;
        text-align: center;
      }
    }
  }
  &__mobile-footer {
    display: none;
  }
  &__item {
    .tv-assignment-item__row--status {
      width: 17%;
      .tv-assignment-request-handler__container {
        white-space: nowrap;
      }
    }
  }
  &-empty {
    display: flex;
    width: 100%;
    text-align: center;
    flex-direction: column;
    margin-top: 3%;

    &-img {
      display: block;
      background-size: contain;
      width: 265px;
      height: 150px;
      margin: 10px auto;
    }

    p {
      font-style: normal;

      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
    }
    &-para {
      font-style: normal;
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    &__assignment-list {
      padding-bottom: 88px;
    }
    &-header {
      display: none;
    }
    &__mobile-footer {
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      padding: 16px;
      height: 88px;

      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      & button {
        width: 100%;
        height: 48px;
      }
    }
  }
}
.sort-column-request {
  display: flex;
  align-items: center;
  width: 100%;
  // justify-content: space-evenly;
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    text-wrap: nowrap;
  }
}

.link-tag {
  color: $BLUE_10;
  text-decoration: underline;
  &:focus-visible {
    outline: 2px solid $OUTLINE_COLOR;
    outline-offset: 1px;
    border-radius: 2px;
  }
}
