@import '../../../styles/variables.scss';
.tv-login-layout {
  &__container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    .tv-logo {
      margin: 15px 24px;
      &:focus-visible {
        outline: 1px solid $OUTLINE_COLOR;
        outline-offset: 14px;
      }
    }
  }
  &__form-container {
    display: flex;
    margin: auto;
    flex-direction: column;
    justify-content: center;
  }
}
