@import '../../../styles/variables.scss';

.tv-notification-request {
  &__container {
    display: flex;
    background: #f1f3f4;
    padding: 20px 10px;
    flex-direction: column;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      border-radius: 8px;
      outline-offset: -5px;
    }
    .tv-assignment-item-mobile__container {
      display: block !important;
    }
    .tv-assignment-request-handler__text {
      display: block;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    .tv-assignment-type-article-icon {
      margin-right: 5px !important;
    }
    .tv-assignment-type-article-name {
      font-size: 16px;
      color: #252525;
      font-weight: 500;
    }
  }

  &__countdown {
    padding: 6px 8px;
    // background: #ff9b4e;
    background: $COLOR_STATUS_AVAILABLE;
    border-radius: 52px;
    color: white;
    font-size: 12px;
    height: 27px;
    width: 64.8px;
    text-align: center;
  }
  &__content {
  }
  &__text {
    display: flex;
    font-size: 12px;
    color: #252525;
    align-items: center;
    width: 100%;
    margin: 13px 0;
    i {
      color: #74737b;
      margin-right: 9.33px !important;
      font-size: 12px;
    }
    &.text-max-length {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @media (max-width: $breakpoint-tablet) {
        max-width: 100%;
      }
    }
  }
  &__action {
    display: flex;
    justify-content: space-between;
    margin-top: 2px;
    .tv-assignment-request-handler__container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .tv-assignment-request-handler__text {
      margin-right: auto;
    }
  }
  @media (max-width: $breakpoint-tablet) {
    &__container {
      padding: 0;
      .tv-assignment-item-mobile__container {
        margin-bottom: 0;
      }
    }
  }
}
