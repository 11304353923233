@import '../../../styles/variables.scss';

.tv-week-view-assignment-item {
  &__container {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: calc(100% - 4px);
    left: 3px;
    min-height: 28px !important;
    border: 0.5px solid #fff;
    height: auto;
    padding: 7px 8px 0px 8px;
    color: #fff;
    margin: 0.5px;
    z-index: 6;

    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      outline-offset: 0px;
      left: 2px;
    }

    &--selected {
      border: 2px $SECONDARY_COLOR solid;
      padding: 5px 6px -2px 6px;
    }
    &--inquiry {
      background-color: $STATUS_INQUIRY_COLOR;
    }
    &--accept {
      background-color: $STATUS_ACCEPTED_COLOR;
    }
    &--performed {
      background-color: $STATUS_DONE_COLOR;
    }
    &--cancelled {
      background-color: $STATUS_CANCELLED_COLOR;
    }
    &--reported {
      background-color: $STATUS_REPORTED_COLOR;
    }
    &--onGoing {
      background-color: $STATUS_ONGOING_COLOR;
    }
    &--availablity {
      .tv-week-view-assignment-item__sub-container {
        border-top: 1px solid #707070;
      }
      i {
        color: #707070;
      }
    }
  }

  &--availablity {
    color: #707070;
    border: none;
    // border-color: #707070;
    cursor: pointer !important;
    padding: 7px 8px !important;
    height: 100%;
  }

  &__header {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    text-transform: capitalize;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-bottom: 4px;

    &__name {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &__time,
    &__countdown {
      width: 100%;
      font-weight: normal;
      font-size: 11px;
      text-align: end;
      line-height: 14px;
    }
    &__time {
      min-width: 60px;
      text-wrap: nowrap;
    }
  }
  &__sub-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.5px;
    border-top: 1px solid #fff;
    padding-top: 8px;
    &--day_calendar {
      flex-wrap: nowrap;
    }
  }
  &__sub-item {
    display: flex;
    width: 100%;
    margin-bottom: 12px;
    align-items: flex-end;
    &__icon {
      color: #fff;
      margin-right: 10px;
      font-size: 15px !important;
    }
    &__value {
      &--text {
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @supports (display: grid) {
          width: clamp(80px, 50%, 110px);
        }
        width: 140px;
      }
      &--link {
        align-items: center;
        text-decoration-line: underline;
      }
    }
  }
  @media (max-width: 768px) {
    &__container {
      padding: 0;
      left: 0;
      width: calc(100% - 4px);
      border: unset;

      .tv-sidebar-assignment-list-item__container {
        position: unset;
        width: 100%;
        border: unset;
      }
    }
    &__sub-item {
      margin-bottom: #{'max(5px, min(1vh, 1.5ch));'};
    }
    &__sub-container--day_calendar {
      flex-wrap: wrap;
    }
  }
  @media (max-width: 1366px) {
    &__header {
      &__time {
        display: none;
      }
    }
  }
}

.tv-week-view-available-now {
  &__container {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: calc(100% - 6px);
    left: 3px;
    min-height: 28px !important;
    height: auto;
    padding: 7px 8px 0px 8px;
    color: $BLUE-10;
    margin: 0.5px;
    z-index: 6;
  }
}
