@import '../../../styles/variables.scss';

.tv-welcome-modal {
  &__container {
    // .tv-modal {
    //   &__container {
    //     width: 620px;
    //   }
    //   &__header-title {
    //     height: 20px;
    //   }
    // }

    @media (max-width: $breakpoint-tablet) {
      padding: 0 21px;
    }

    .tv-modal__container {
      max-width: 620px;
      text-align: center;
      padding: 32px 24px 24px;
      width: auto;
      height: auto;
      @media (max-width: $breakpoint-tablet) {
        padding: 32px 16px 22px;
        transform: translate(0, 10%);
      }
    }
  }
  &__content {
    padding: 48px 0 24px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  &__welcome-message {
    font-size: 32px;
    color: #252525;
  }
  &__text {
    margin-top: 20px;
    font-size: 18px;
    color: #74737b;
    width: 100%;
  }
  &__checkbox {
    margin-top: 6px;
    .tv-checkbox__label {
      font-size: 16px;
      color: #252525;
    }
  }
  &__actions {
    display: flex;
    margin-top: 22px;
    width: 100%;
    justify-content: space-between;
    & .tv-button {
      width: 100%;
      margin-right: 32px;
      &:nth-last-child(1) {
        margin-right: 0px;
      }
    }
  }
  //   @media (max-width: 768px) {
  //     &__container {
  //       .tv-modal {
  //         &__container {
  //           width: 100vw;
  //           height: 100vh;
  //         }
  //         &__header-title {
  //           height: 20px;
  //         }
  //       }
  //     }
  //     &__actions {
  //       flex-direction: column;
  //       position: fixed;
  //       width: calc(100vw - 36px);
  //       bottom: 16px;
  //       margin-bottom: 0;
  //       margin-top: 10px;
  //       & .tv-button {
  //         margin-top: 8px;
  //         margin-right: 14px;
  //       }
  //     }
  //   }
}
