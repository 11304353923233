@import '../../../styles/variables.scss';

.tv-field {
  &__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    position: relative;
  }
  &__label {
    font-family: $SECONDARY_FONT;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
  }
  &__input {
    border: 1px solid $BORDER_COLOR;
    height: 48px;
    box-sizing: border-box;
    padding: 14px 16px;
    border-radius: 4px;
    margin: 8px 0px;
    outline: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    font-family: $SECONDARY_FONT;
    &--error {
      border: 1px solid $BLUE-10 !important;
      border-radius: 4px;
    }
    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
    }
  }
  &__error {
    font-family: Circular Std;
    font-style: normal;
    color: $BLUE-10;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
}
