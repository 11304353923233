@import '../../../styles/variables.scss';

.contact_card_wrapper {
  display: flex;
  flex-direction: column;
  // margin-bottom: 230px;
  width: 100%;
  height: auto;
  &-details {
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  .col {
    flex-basis: unset;
  }
  .question-and-answer {
    font: 500 20px /35px $PRIMARY_FONT;
    margin-top: 10px;
    a {
      color: $BLUE-10;
      text-decoration: underline;
      position: relative;
      outline: none;
      &:focus-visible {
        // outline: 2px solid $OUTLINE_COLOR;
        &::after {
          content: '';
          position: absolute;
          top: -3px;
          left: -5px;
          width: 108%;
          height: 130%;
          border: 2px solid $OUTLINE_COLOR;
          border-radius: 4px;
        }
      }
    }
  }
}
:root {
  --page-margin: max(25px, min(5%, 25px));
}
.page-title {
  display: flex;
  font: 400 28px /35px $PRIMARY_FONT;
  margin-top: 60px;
  @media screen and (max-width: 768px) {
    margin-top: 38px;
    font: 400 24px /30px $PRIMARY_FONT;
    flex-direction: column;
  }
}

.page-title__new {
  display: flex;
  font: 500 24px /35px $PRIMARY_FONT;
  margin-top: 40px;
  @media screen and (max-width: 768px) {
    margin-top: 40px;
    font: 400 24px /30px $PRIMARY_FONT !important;
    flex-direction: column;
  }

  a {
    color: $BLUE-10;
    text-decoration: underline;
    position: relative;
    outline: none;
    &:focus-visible {
      &::after {
        content: '';
        position: absolute;
        top: -6px;
        left: -5px;
        width: 104%;
        height: 40%;
        border: 2px solid $OUTLINE_COLOR;
        border-radius: 4px;
        @media (max-width: $breakpoint-tablet) {
          content: '';
          position: absolute;
          top: -2px;
          left: -5px;
          width: 104%;
          height: 125%;
          border: 2px solid $OUTLINE_COLOR;
          border-radius: 4px;
        }
        @media (max-width: 1024px) and (orientation: landscape) {
          content: '';
          position: absolute;
          top: -2px;
          left: -1px;
          width: 104%;
          height: 56%;
          border: 2px solid #f38025;
          border-radius: 4px;
        }
      }
    }
  }
}

.page-section-body__new {
  font: 100 18px /24px $SECONDARY_FONT;
  margin-top: 5px;
  text-align: justify;

  a {
    color: $BLUE-10;
    font: 100 16px /24px $SECONDARY_FONT;
  }
}

.title {
  font: 400 28px/35px $PRIMARY_FONT;
}
.page-footer {
  display: flex;
  position: absolute;
  left: 0;
  height: 160px;
  bottom: 0;
  justify-content: center;
  background-color: #f8f8f8;
  width: 100%;

  img {
    float: right;
    margin: 0 32px 40px 0;
  }
  @media screen and (max-width: 768px) {
    height: 94px;
    justify-content: center;
    position: fixed;
    img {
      float: unset !important;
      margin: 0 auto 32px;
    }
  }
}

.generic_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 25px;
  min-width: 300px;
  width: 100%;
  min-height: 320px;
  border-radius: 4px;
  @media screen and (max-width: 768px) {
    height: 150px;
    border-radius: 4px;
    background: #fff;
    width: auto;
  }
}
.contact_image {
  min-height: 276px;
  @media screen and (max-width: 768px) {
    min-height: 180px;
    img {
      height: 100% !important;
    }
  }
  img {
    width: 100%;
    height: auto;
  }
}
.contact_city {
  padding-top: 15px;
  margin-bottom: 8px;
  margin-top: 16px;
  font: 24px/30px $PRIMARY_FONT;
  @media screen and (max-width: 768px) {
    margin-top: 0px;
    font: 24px/30px $PRIMARY_FONT;
  }
}

.contact_address_wrapper {
  font: 16px/20px $PRIMARY_FONT;
  @media screen and (max-width: 768px) {
    font: 16px/20px $PRIMARY_FONT;
  }
  &_element {
    font-family: $SECONDARY_FONT;
  }
}
.contact_method_wrapper {
  padding-top: 15px;
  display: inline-flex;
  padding-bottom: 15px;
  font: 16px/20px $PRIMARY_FONT;

  &-name {
    font-family: $SECONDARY_FONT;
  }

  &-number a {
    font-family: $SECONDARY_FONT;
    color: $BLUE-10;
    margin-right: 5px;
    ::before {
      content: ':';
      color: $COMMON_TEXT_COLOR;
    }
  }
  @media screen and (max-width: 768px) {
    font: 16px/20px $PRIMARY_FONT;
  }
}
.faq_wrapper {
  display: flex;
  flex-direction: column;
  margin: 0px 0 75px 0;
  @media screen and (max-width: 768px) {
    margin: 0px 0 0px 0;
  }
}

.drop_accordian {
  border-radius: 4px;
  margin-top: 20px;
  display: flex;
  border: 1px solid $BORDER_COLOR;
  font: 16px/20px $PRIMARY_FONT;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.question_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  font: 500 19px/24px $PRIMARY_FONT;
  padding: 0px 22px 0px 24px;
  :hover {
    cursor: pointer;
  }
}
.heading {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-weight: 500;
  justify-content: space-between;
  padding: 24px 0 24px 0;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.icon_wrapper {
  :hover {
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    text-align: center;
  }
}
.icon {
  font-size: 16px;
}

.body {
  display: flex;
  font: 16px/20px $SECONDARY_FONT;
  flex-direction: column;

  p {
    margin-bottom: 0;
    margin-top: 16px;
    font-weight: 100;
    font-family: $SECONDARY_FONT;
    &:first-child {
      margin-top: 0px;
    }
    &:last-child {
      margin-bottom: 24px;
    }

    h6 {
      font-family: $PRIMARY_FONT;
    }
  }

  a {
    font-family: $SECONDARY_FONT;
    color: $BLUE-10;
  }
}

.page_content {
  color: $COMMON_TEXT_COLOR;
  display: flex;
  flex-direction: column;
  margin: 0 var(--page-margin);
  padding-bottom: 110px;
  width: 100%;
  padding-inline: 5px;
  @supports not (margin: var(--page-margin)) {
    margin: 0 16px;
  }
  @media screen and (max-width: 768px) {
    margin: 0;
    height: fit-content;
    overflow: scroll;
  }
  overflow: hidden;
}
.contact_now_button {
  font-family: $PRIMARY_FONT;
  background: $BLUE-10;
  border: none;
  outline: none;
  color: white;
  padding: 14px;
  width: 30%;
  border-radius: 6px;
  font-size: 16px;
  margin-bottom: 15px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.contact_now_button_wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.single-question {
  font-family: $PRIMARY_FONT;
}
