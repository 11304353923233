@import '../../../styles/variables.scss';

.tv-availability-now-modal {
  &__label {
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */
    color: #333333;
    font-weight: 500;

    display: flex;
    align-items: center;
    text-align: center;
    margin-right: 10px;

    @media (max-width: 1024px) and (orientation: landscape) {
      display: none;
    }
  }
  &__container {
    & > .tv-modal {
      &__container {
        min-height: 387px;
        max-width: 672px;
      }
    }
    &--empty {
      & > .tv-modal {
        &__container {
          min-height: 200px;
          max-width: 672px;
        }
      }
    }
  }
  &__content {
    padding: 22px 0 32px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    .tv-timepicker__container {
      width: 100%;
    }
    &--empty {
      padding: 22px 0 32px 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      min-height: inherit;
      & > .tv-availability-now-modal__actions {
        flex: 1;
        align-items: flex-end;
      }
    }
  }
  &__text {
    font-size: 18px;
    text-align: center;
    color: #4a5568;
    margin-bottom: 0;
    &-new {
      font-size: 14px;
      display: flex;
      justify-content: end;
      margin-top: 14px;
      font-weight: 500;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    margin-bottom: 46px;
  }
  &__field_group {
    display: flex;
    width: 100%;
  }
  &__field {
    max-width: 295px;
    &__label {
      display: flex;
      align-content: center;
      width: 100%;
      padding: 0 10px;
      margin: auto;
    }
  }
  &__error {
    color: $BLUE-10;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    margin-top: 16px;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  &__actions {
    display: flex;
    margin-top: 22px;
    width: 100%;
    justify-content: space-between;
    & .tv-button {
      width: 100%;
      margin-right: 32px;
      &:nth-last-child(1) {
        margin-right: 0px;
      }
    }
  }
  &__seperator {
    border-top: 1px solid #e0e0e0;
    margin: 23px 0 30px;
    width: 100%;
  }

  &__errors {
    color: $BLUE-10;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    margin-top: 16px;
    align-items: center;
    width: 100%;
  }

  @media (max-width: 768px) {
    &__container {
      & > .tv-modal {
        &__container {
          overflow-y: scroll;
          position: relative !important;
          min-height: unquote('max(100vh, 750px)') !important;
          max-width: fit-content;
        }
      }
    }
    &__content {
      height: calc(100vh - 72px);
    }
    &__text {
      text-align: left;
    }
    &__field_group {
      flex-direction: column;
    }
    &__form {
      flex-direction: column;
      width: 100%;
      margin: 0;
    }
    &__field {
      &__label {
        display: flex;
        margin: 0 0 16px 0;
        padding: 0px;
      }
    }
    &__error {
      justify-content: start;
    }
    &__seperator {
      margin: 16px 0 24px;
    }
    &__actions {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 10px;
      background: #fff;
      margin-bottom: 16px;
      & .tv-button {
        margin-right: 14px;
      }
      &-emergency {
        position: relative;
      }
    }
  }
}
