@import '../../../styles/variables.scss';

.tv-tab_list {
  &__container {
    &-tabs {
      // width: 352px;
      width: 26vw;
      &_contents {
        width: 100%;
      }
    }
    padding: 0 12px;
    display: flex;
    &-tabs-desktop {
      display: block;
      font-family: $PRIMARY_FONT;
    }
    &-tabs-mobile {
      display: none;
    }
  }
  &-tab {
    color: #252525;
    background: #fff;
    border-width: 0px;
    height: 48px;
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    text-align: left;
    background: #fff;
    color: $BLUE-10;
    padding: 14px 12px;
    text-decoration: underline;
    &:focus {
      outline: 0;
    }
    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      outline-offset: -6px;
      border-radius: 8px;
    }

    &--disabled {
      color: #707070 !important;
    }

    &-disabled {
      font-weight: 500;
      color: #252525;
      text-decoration: none;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    &__container {
      padding: 0;
      display: block;
      &-tabs {
        width: auto;
        margin-bottom: 32px;
      }
      &-tabs-desktop {
        display: none;
      }
      &-tabs-mobile {
        display: block;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    &__container {
      padding: 0;
      display: block;
      &-tabs {
        width: auto;
        margin-bottom: 32px;
      }
      &-tabs-desktop {
        display: none;
      }
      &-tabs-mobile {
        display: block;
      }
    }
  }
}
