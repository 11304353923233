@import '../../../styles/variables.scss';

.feedback{
    &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        &-title {
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 20px;
            margin-top: 20px;
            font-family: $PRIMARY_FONT;
        }
        &-para {
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            padding-top: 20px;
            color: $FONT_LIGHT_GRAY_COLOR;
            font-family: $PRIMARY_FONT;
        }
    }
    &-star {
        display: flex;
        justify-content: center;
    }
}

.feedback-error-message{
    font: 500 13px/20px $PRIMARY_FONT;
    color: $BLUE-10;
}

.show-feedback-error{
    border: 1px solid $BLUE-10
}