@import '../../../styles/variables.scss';

.tv-assignment-overview {
  width: 100%;
  &__outer-container {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 0 24px;
    position: sticky;
    top: 0;
    .tv-star-rating__icon {
      span {
        margin: 0 3px;
        i {
          margin-top: 9px;
          font-size: 15px;
        }
      }
    }
    hr {
      margin: 12px 0 16px 0;
      width: 100%;
    }
    .back-icon {
      i {
        display: none;
      }
    }
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 11px;
      button {
        height: auto !important;
      }
      .assignment-number {
        font-family: Circular Std;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        color: #252525;
      }
      .back-icon,
      .close-icon {
        font-size: 20px;
        i:before {
          -webkit-text-stroke: 1.5px #fff;
          font-size: 24px;
        }
      }
    }
    &__wrapper {
      padding: 0 17px;
    }
  }
  &__mobile-navigator {
    display: none !important;
    bottom: 0;
  }
  &__container {
    hr {
      margin: 16px 0;
      width: 100%;
    }
  }
  &__content {
    width: 100%;

    &-area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      // highlight changed text

      &.labelled {
        background-color: #f4d0dd;
        padding: 8px 16px;
        border-radius: 8px;
      }

      &.red-text > .tv-assignment-overview__text > span {
        color: $BLUE-10 !important;
        font-weight: 500;
      }
      span {
        &:focus-visible {
          outline: 2px solid $OUTLINE_COLOR;
          border-radius: 2px;
          outline-offset: 1px;
        }
      }
    }
  }
  &__assignment-type {
    margin-bottom: 0;
    padding: 0 2px;
    .tv-assignment-type-article-name {
      font-size: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    &--small {
      font-size: 14px;

      .tv-assignment-type-article-icon {
        margin-right: 9px !important;
        font-size: 16px;
        &:before {
          font-size: 14px;
        }
      }
      .tv-assignment-type-article-name {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
  &__location {
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    margin-left: 35px;
    margin-top: 4px;
  }
  &__amount {
    i {
      color: #000000 !important;
    }
  }
  &__text {
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #252525;

    i {
      font-size: 14px;
      color: #74737b;
      width: 14px;
      height: 14px;
      text-align: center;
    }
    span,
    a {
      margin-left: 9px;
      padding-top: 1px;
      overflow: hidden;
      white-space: normal;
      // text-overflow: ellipsis;
      width: 250px;
      text-decoration: none;
      color: #252525;
    }
    a:hover {
      color: #4058e0;
    }
  }
  &__footer {
    display: flex;
    width: 100%;
    margin-top: 24px;
    z-index: 1;
    button {
      width: 100%;
    }
    button:nth-last-child(2) {
      margin-right: 16px;
    }
    .left-button {
      margin-right: 8px;
    }
    .right-button {
      margin-left: 8px;
    }
    .full-button {
      width: 100%;
    }
    .cancel-button {
      width: 100%;
      height: 20px;
      color: $BLUE-10;
    }
    .fill-time-report-button {
    }
  }
  &--bankid-icon {
    width: 18px !important;
    height: 11px !important;
    background-repeat: no-repeat;
    margin-right: 10px;
    margin-left: -2px;
    background-size: contain;
  }

  @media (max-width: $breakpoint-tablet) {
    min-height: auto;
    min-width: auto;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: #fff;
    left: 0;
    top: 0;
    z-index: 1;

    &__outer-container {
      height: 100%;
      padding: 28px 0;
      &__wrapper {
        height: calc(100vh - 175px);
        overflow-y: scroll;
      }
      .back-icon {
        i {
          display: block;
        }
      }
    }
    &__text {
      .mobile-expand > & {
        align-items: unset;
        & > span {
          overflow: auto;
          white-space: normal;
          text-overflow: inherit;
          position: relative;
          top: -5px;
        }
      }
    }
    .tv-assignment-navigator__container {
      position: absolute;
      width: 100%;
      padding: 5px 30px;
    }
    &__mobile-navigator {
      display: flex !important;
      position: fixed !important;
      background-color: #fff;
    }
    &__footer {
      display: flex;
      width: 100%;
      margin-top: 24px;
      position: fixed;
      bottom: 57px;
      left: 0;
      padding: 0 15px;
      background-color: #fff;
      z-index: 1;
      .cancel-button {
        height: 30px;
      }
    }
  }
}
.button-group-wrapper {
  width: 100%;
}

.button-wrapper {
  margin-bottom: 5%;
}
