@import "../../../styles/variables.scss";
.tv-setting {
  &__container {
    width: 100%;
    height: 100vh;
  }
  &__panel-title {
    font-weight: 500;
    font-size: 28px;
    line-height: 35px;
    color: #252525;
    font-family: $PRIMARY_FONT;

    &-sub {
      font-weight: 300;
      font-size: 16px;
      letter-spacing: -0.2px;
      padding-top: 12px;
      color: #252525;
      font-family: $SECONDARY_FONT;
    }
  }
  &-dropdown__button {
    &:focus-visible {
      outline: none;
      .tv-avatar__img {
        outline: 2px solid $OUTLINE_COLOR;
        outline-offset: 2px;
      }
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .tv-display-mobile {
    &-grid {
      display: grid !important;
    }
    &-hide {
      display: none !important;
    }
    &-show {
      display: block !important;
    }
  }
}
