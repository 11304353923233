@import '../../../styles/variables.scss';

.tv-calendar_range_navigator {
  border-radius: 4px;
  border: 1px solid $BORDER_COLOR;
  display: inline-flex;
  font-size: 16px;
  line-height: 20px;
  margin-right: 24px;
  text-transform: capitalize;
  & span {
    padding: 14px 6.5px;
    font-family: $PRIMARY_FONT;
  }

  &__icon {
    color: $NAVIGATION_COLOR;
    padding: 14px 0 14px 16px;
    border-radius: 4px;
    &:last-child {
      padding: 14px 16px 14px 0;
    }
    &:hover {
      color: $COMMON_TEXT_COLOR;
    }
    &:focus-visible {
      i {
        outline: 2px solid $OUTLINE_COLOR;
        outline-offset: 5px;
        border-radius: 1px;
      }
    }
  }
  @media (max-width: 768px) {
    margin-right: 0px;
    margin-bottom: 16px;
    width: 100%;
    justify-content: space-between;

    & span {
      min-width: 83px;
      text-align: center;
    }
    &__icon {
      padding: 8px 16px;
      &:last-child {
        padding: 8px 16px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    margin-right: 0px;
    margin-bottom: 16px;
    width: 100%;
    justify-content: space-between;

    & span {
      min-width: 83px;
      text-align: center;
    }
    &__icon {
      padding: 8px 16px;
      &:last-child {
        padding: 8px 16px;
      }
    }
  }
}
