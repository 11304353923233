@import '../../../styles/variables.scss';

.tv-assignment-item {
  &__container {
    display: flex;
    background-color: #fafcfd;
  }
  &__row {
    height: 47px;
    display: flex;
    flex-wrap: wrap;
    &--type {
      width: 10%;
      padding-left: 18px;
    }
    &--date {
      width: 10%;
    }
    &--number {
      width: 10%;
    }
    &--pay {
      width: 5%;
    }
    &--customer {
      width: 10%;
    }
    &--language {
      width: 10%;
    }
    &--countdown {
      width: 10%;
    }
    &--status {
      width: 10%;
      display: flex;
      text-align: end;
      padding-right: 10px;
      .tv-assignment-item-text {
        display: flex;
        justify-content: flex-end;
      }
    }
    & > div {
      flex-grow: 1;
    }
  }
  &-selected {
    // background-color: #322878 !important;
    background-color: $BLUE-10 !important;
    div,
    i {
      color: white !important;
    }
  }
  &-text {
    display: flex;
    font-size: 14px;
    color: #252525;
    align-items: center;
    width: 100%;
    i {
      color: #74737b;
      margin-right: 9.33px !important;
      position: relative;
      bottom: 3px;
      font-size: 16px;
    }
    &.text-max-lenght {
      max-width: calc(96vw / 7);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @media (max-width: $breakpoint-tablet) {
        max-width: 100%;
      }
    }
  }
  // &-expiry-time {
  //   color: #ff9b4e;
  // }

  // Mobile View
  @media (max-width: $breakpoint-tablet) {
    &__container {
      background-color: unset;
    }
    &-text {
      margin-top: 12px;
      font-size: 12px;
    }
    &-expiry-time {
      color: #ffffff;
      background: $COLOR_STATUS_AVAILABLE;
      padding: 6px 15px;
      font-size: 12px;
      border-radius: 52px;
    }
    &-mobile {
      &__container {
        display: flex;
        width: 100%;
        border: 1px solid #e0e0e0;
        background-color: #ffffff;
        padding: 16px 10px;
        border-radius: 4px;

        margin-bottom: 16px;
        hr {
          margin: 17px 0;
          border-top: 1px solid #ebebeb;
        }
      }
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &__content {
        .material-icons {
          font-size: 16px;
          position: relative;
          top: 3px;
          right: 1px;
        }
      }
      &__text {
        margin: 17px 0;
      }
      &__group {
        display: flex;
        justify-content: space-between;
      }
      &-contact-person {
        max-width: 60%;
      }
    }
  }
}
