@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.tv-notification {
  &__page {
    width: 80%;
    margin-left: auto;
    &__title {
      font-weight: 500;
      font-size: 24px;
      color: #252525;
      margin-bottom: 20px;
    }
  }

  &__container {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
    position: absolute;
    top: 65px;
    right: 5%;
    min-width: 300px;
    z-index: 49;
    text-align: left;
    height: auto;
    width: 321px;
    font-family: $PRIMARY_FONT;
    &-fluid {
      width: 100%;
    }
    .tv-notification__list {
      .tv-general-notification__container {
        &:focus-visible {
          outline-offset: -5px;
          border-radius: 8px;
        }
      }
    }
  }
  &__list {
    max-height: 60vh;
    overflow-y: scroll;
    z-index: 10;

    @include tv-scroll-bar();
  }
  &__no-item {
    padding: 10px;
    text-align: center;
    margin: auto;
  }
  &__button {
    width: 100%;
    display: flex;
    padding: 14px;
    justify-content: center;
    border: none;
    outline: none;
    background-color: transparent;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: $BLUE-10;
    &:focus {
      outline: none;
    }
    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      border-radius: 4px;
    }
  }
  @media (max-width: $breakpoint-tablet) {
    &_header {
      overflow-y: hidden;
    }
    &_header {
      margin-right: 24px;
    }
    &__page {
      width: 100%;

      &__title {
        display: none;
      }

      .tv-assignment-type {
        &__container {
          align-items: center;
        }
        &-article-name {
          font-weight: 500;
          line-height: 20px;
          font-size: 14px;
          color: #252525;
        }
        &-article-icon {
          margin-right: 11px !important;
          color: #74737b;
          font-size: 20px;
          &:before {
            font-size: 13px;
          }
          &:before {
            font-size: 20px;
          }
          @include status-colors();
        }
      }
      .tv-notification-request__container {
        padding: 0;
        .tv-assignment-item-mobile__container {
          margin-bottom: 0;
        }
      }
    }
    &__container {
      position: fixed;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      top: 72px;
      overflow-y: scroll;
    }
    &__list {
      max-height: none;
      // max-height: calc(100vh - 72px);
      // overflow-y: scroll;
      // z-index: 10;
      // @media (pointer: none), (pointer: coarse) {
      //   padding-bottom: 60px;
      // }

      // @include tv-scroll-bar();
    }
    // &__button {
    //   display: none;
    // }
  }
}
