@import '../../../styles/variables.scss';

.tv-tab_button {
  background: #fff;
  border-width: 0px;
  height: 48px;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  background: #fff;
  color: #252525;
  padding: 14px 8px;
  font-family: $PRIMARY_FONT;
  &:focus {
    outline: 0;
  }
  &--disabled {
    color: #707070 !important;
  }
}
