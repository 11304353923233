@import '../../../styles/variables.scss';

.mobile-wrapper {
  left: -200px;
}

.tv-mobile-navigation {
  background: #fff;
  padding: 0px 16px;
  display: none;
  width: 100%;
  z-index: 60;
  right: 0;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
  &_menu_area {
    height: calc(100vh - 72px) !important;
  }
  &__available-container {
    margin-left: auto;
    margin-right: 15px;
  }

  &-fixed {
    top: 0;
    position: fixed;
    z-index: 999;
  }

  .tv-left-pannel__logo {
    float: left;
    justify-content: end;
  }
  .tv-notification-icon__container {
    // margin-left: auto;
    // width: 36px;
    // margin-right: 24px;
    @media (max-width: 768px) and (min-width: 576px) {
      &__container {
        min-height: calc(100vh - 100px);
      }
    }
  }
  .nav-item-mobile {
    text-align: center;
    display: block;
    font-weight: 600;
    color: $BLUE-10;
    font-family: $PRIMARY_FONT;
    font-size: 17px;
    margin: 3px 20px;
    padding: 10px;
    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      align-self: center;
      border-radius: 6px;
    }
  }
  &__flex {
    align-items: center;
    height: 72px;
    a {
      &:focus-visible {
        outline-color: $OUTLINE_COLOR;
        //outline: 1px solid $OUTLINE_COLOR;
        outline-offset: 2px;
      }
    }
  }

  &__nav-bar {
    text-align: center;
    display: block;
    font-weight: 600;
    color: $BLUE-10;
    font-family: $PRIMARY_FONT;
    margin: 3px 20px;
    padding-top: 3px;
  }
  &__menubtn {
    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      border-radius: 4px;
      align-self: center;
      @media (max-width: $breakpoint-tablet) {
        outline-offset: 2px;
      }
    }
  }
  .nav-active {
  }
  @media (max-width: 1024px) {
    display: block !important;
  }
}
