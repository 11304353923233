// Tiny Scrollbar
@mixin tv-scroll-bar() {
  // Firefox scrollbar
  -moz-appearance: none !important;
  scrollbar-width: thin;
  scrollbar-color: #bbbbbb;

  // IE scrollbar
  scrollbar-face-color: #bbbbbb;
  scrollbar-shadow-color: #ffffff;
  scrollbar-highlight-color: #ffffff;

  // Webkit scrollbar
  &::-webkit-scrollbar {
    width: 4px;
    margin-top: 7px;
    position: absolute;
    right: -1px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ffffff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #bbbbbb;
    border-radius: 8px;
    height: 154.37px;
    width: 4px;
  }
}
@mixin status-colors() {
  &--reported {
    color: $STATUS_REPORTED_COLOR !important;
  }
  &--inquiry {
    color: $STATUS_INQUIRY_COLOR !important;
  }
  &--accept {
    color: $STATUS_ACCEPTED_COLOR !important;
  }
  &--performed {
    color: $STATUS_DONE_COLOR !important;
  }
  &--cancelled {
    color: $STATUS_CANCELLED_COLOR !important;
  }
}

@mixin material-icons() {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
