@import '../../../styles/variables.scss';

.tv-welcome-message {
  color: #74737b;
  bottom: -50px;
  z-index: 20;
  width: 320px;
  min-width: 320px;
  left: 16px;
  @supports (display: grid) {
    left: #{'max(16px, min(1vw, 32px))'};
  }
  position: absolute;

  &__text-wrapper {
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    line-height: 19px;
    align-items: center;
  }
  &__text-area {
    display: flex;
  }
  &__text {
    margin-right: 3px;
  }

  i {
    &:before {
      font-size: 16.67px;
    }
  }
  span {
    font-size: 20px;
  }

  @media (max-width: 768px) {
    position: absolute;
    order: 1;
    top: 10px;
    padding-left: 16px;
    margin-top: 16px;
    margin-bottom: 8px;
    justify-content: space-between;
    display: flex;
    width: 100%;
    color: #74737b;
    z-index: -1;
    left: 0;
    height: fit-content;
    align-items: center;
    &__text-area {
    }

    &__text {
      font-weight: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      margin-right: 8px;
    }
    &__customer-name {
      font-size: 15px;
      line-height: 19px;
      display: flex;
      align-items: flex-end;
    }
    &__schedule-message {
      font-size: 15px;
      line-height: 19px;
      display: none;
    }
    &__time {
      font-size: 15px;
      line-height: 19px;
      margin-top: 0 !important;
      padding-right: 16px;
      i {
        &:before {
          font-size: 15px;
        }
      }
      span {
        font-size: 15px;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    position: absolute;
    order: 1;
    // top: 10px;
    padding-left: 16px;
    margin-top: 16px;
    margin-bottom: 8px;
    justify-content: space-between;
    display: flex;
    width: 100%;
    color: #74737b;
    z-index: -1;
    left: 0;
    height: fit-content;
    align-items: center;
    &__text-area {
    }

    &__text {
      font-weight: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      margin-right: 8px;
    }
    &__customer-name {
      font-size: 15px;
      line-height: 19px;
      display: flex;
      align-items: flex-end;
    }
    &__schedule-message {
      font-size: 15px;
      line-height: 19px;
      display: none;
    }
    &__time {
      font-size: 15px;
      line-height: 19px;
      margin-top: 0 !important;
      padding-right: 16px;
      i {
        &:before {
          font-size: 15px;
        }
      }
      span {
        font-size: 15px;
      }
    }
  }
}
