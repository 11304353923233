@import '../../../styles/variables.scss';

.tv-authentication-page {
  &__Link {
    display: flex;
    margin: 16px auto;
    color: $BLUE-10;
    justify-content: center;
    &:focus-visible {
      outline: 1px solid $OUTLINE_COLOR;
      border-radius: 4px;
      outline-offset: 4px;
    }
  }
  &__accessibility-statement-link{
    display: flex;
    margin: 4px auto;
    color: $BLUE-10;
    justify-content: center;
    cursor: pointer;
    &:focus-visible {
      outline: 1px solid $OUTLINE_COLOR;
      outline-offset: 5px;
      border-radius: 2px;
    }
  }
}
