@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.tv-assignment-time-report-preview {
  &__container {
    & > .tv-modal {
      &__container {
        min-height: 387px;
        max-width: 672px;
      }
    }
  }
  &__content {
    padding: 18px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    &-area {
      @include tv-scroll-bar();
    }
  }
  &__title {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    &-wrapper {
      display: flex;
      justify-content: space-between;
    }
    &-area {
      padding: 0 !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__btn-text {
    font-size: 16px;
    color: $BLUE-10;
    height: auto;
  }
  &__label {
    font-size: 14px;
    font-weight: 500;
  }
  &__comment {
    &-wrapper {
    }
    &-label {
      label {
        font-size: 14px !important;
      }
    }
  }
  &__wrapper {
    display: flex;
    font-weight: normal;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    justify-content: space-between;
    padding: 0px;
    margin-top: 16px;
  }
  &__actions {
    display: flex;
    margin-top: 15px;
    width: 100%;
    justify-content: space-between;
    & .tv-button {
      width: 100%;
      margin-right: 32px;
      &:nth-last-child(1) {
        margin-right: 0px;
      }
    }
  }
  &__seperator {
    width: 100%;
    border-top: 1px solid #e0e0e0;
    margin: 18px 0;
  }
  &__link {
    text-decoration: none;
    color: $BLUE-10;
    margin-left: 6px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  &__errors {
    color: $BLUE-10;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    margin-top: 16px;
    align-items: center;
    width: 100%;
  }
  &__control {
    display: flex;
    flex: 0.5;
    width: 100%;
    margin-right: 16px;
    flex-direction: column;
    font-size: 16px;
    line-height: 20px;

    & label {
      font-size: 14px;
      margin-bottom: 0;
    }
    &-time {
      display: flex;
      flex: 1.5;
      width: 100%;
    }
  }
  &__date-time {
    display: flex;
    &-mobile {
      display: none;
    }
  }
  &__content-right {
    display: flex;
    flex: 1;
  }
  &__field {
    margin-right: 8px;
    .distance-in-km {
      margin: 0;
      input {
        margin: 0;
      }
    }
    &-comment {
      height: 72px;
      padding: 16px;
    }
    &-edit {
      label {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .attach-receipe {
      margin-bottom: 0;
      .material-icons {
        position: absolute;
        top: 12px;
        left: 10px;
        color: #74737b;
      }
      input {
        margin: 0;
      }
    }
  }

  &__checkbox {
    .tv-checkbox__label {
      font-size: 14px;
    }
  }
  &__attachments {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
  }
  @media (max-width: 768px) {
    &__container {
      & > .tv-modal {
        &__container {
          position: fixed;
          height: 100vh;
        }
      }
    }
    &__content {
      height: calc(100vh - 72px);
    }
    &__content-area {
      height: calc(100vh - 180px);
      overflow-y: scroll;
    }
    &__content-right {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    &__seperator {
      margin: 25px 0 14px;
      &-top {
        margin: 10px 0 20px;
      }
    }
    &__wrapper {
      justify-content: center;
      align-items: flex-start;
      .tv-tab_button_row__container:not(.tv-mobile--hide) {
        display: inline-flex;
        width: 226px;
        margin-bottom: 12px;
      }
      &-travel-parking {
        margin-bottom: 40px;
      }
      &-other-expense {
        margin-bottom: 38px;
      }
    }
    &__title {
      &-area {
        flex-direction: column;
        align-items: flex-start;
      }
      &-label {
        font-size: 14px;
      }
    }
    &__control {
      flex: 1;
      .react-datepicker-wrapper,
      .react-datepicker-wrapper input {
        width: 100%;
      }
      &:nth-last-child(1) {
        margin-right: 0;
      }
    }
    &__field {
      margin-right: 16px;
      &-group {
        flex-direction: column;
        .tv-assignment-time-report-preview__content-right {
          display: flex;
          flex-direction: row;
          width: 100%;
        }
      }
    }
    &-row-mobile {
      flex-direction: row !important;
    }
    &__date-time {
      display: none;
      &-mobile {
        display: flex;
      }
    }
    &__actions {
      flex-direction: column;
      bottom: 0;
      margin-bottom: 0;
      margin-top: 10px;
      & .tv-button {
        margin-top: 8px;
        margin-right: 14px;
      }
    }
  }
}
