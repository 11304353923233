.semester-info {
  align-items: flex-start !important;

  .icon-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 8px;

    &--single-day {
      justify-content: flex-start;
      i {
        margin-left: 10px;
      }
    }
  }
}
