@import '../../../styles/variables.scss';

.tv-star-rating {
  &__icon {
    font-size: 24px;
    color: #ff9b4e;
    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      border-radius: 2px;
    }
  }
}
