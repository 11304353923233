@import "../../../styles/variables.scss";
.tv-calendar {
  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100% !important;
    @supports (display: flex) {
      width: calc(100vw - (320px + 2 * #{"max(16px, min(2vw, 32px))"}));
    }
    position: relative;

    .tv-calendar__mobile-footer {
      @media (max-width: 1024px) and (orientation: landscape) {
        display: flex;
        margin-top: 10px;
        gap: 20px;
        .tv-button {
          width: 100%;
        }
      }
    }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) { 
      z-index: 1;
      .container-wrapper {
        max-height: 200px;
      }
     }
  }
  &__navigation-container {
    display: flex;
    margin-bottom: 16px;
    .tv-tab_button {
      font-weight: 400;
      &:focus-visible {
        outline: 2px solid $OUTLINE_COLOR;
        border-radius: 8px;
        outline-offset: -5px;
      }
    }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      .tv-mobile--hide {
        // display: none;
      }
    }
  }
  &__naviagte-today {
    width: 105px;
    margin-left: 24px;
  }
  &__availability-exception-button {
    position: absolute;
    right: 0;
  }
  &__mobile-footer {
    display: none;
  }
  @media (max-width: 768px) and (orientation: portrait) {
    &__container {
      width: 100%;
      margin-bottom: 88px;
      z-index: 1;
      // margin-top: 56px;
    }
    &__navigation-container {
      flex-wrap: wrap;
      .tv-tab_button {
        width: 50%;
      }
      .tv-tab_button_row__container {
        width: 50%;
      }
    }
    &__availability-exception-button {
      display: none;
    }
    &__naviagte-today {
      width: calc(50% - 24px);
      margin-right: 0;
    }

    &__mobile-footer {
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      padding: 13px 16px;
      background-color: #fff;
      height: 88px;
      z-index: 6;
      & button {
        width: 100%;
        white-space: nowrap;
        margin-right: 12px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  @media (max-width: 1024px) and (orientation: landscape) {
    &__navigation-container {
      flex-wrap: wrap;
      .tv-tab_button_row__container {
        width: 50%;
      }
      .tv-tab_button {
        width: 50%;
      }
    }
    &__naviagte-today {
      width: 105px;
      margin-left: 4px;
    }
    &__availability-exception-button {
      display: none;
    }
  }

  @media (max-width: 768px) and (min-width: 576px) {
    &__container {
      width: 100%;
      margin-bottom: 88px;
      z-index: 1;
      margin-top: 56px;
    }
    &__navigation-container {
      flex-wrap: wrap;
      .tv-tab_button_row__container {
        width: 50%;
      }
      .tv-tab_button {
        width: 50%;
      }
    }
    &__naviagte-today {
      width: calc(50% - 24px);
      margin-right: 0;
    }
    &__availability-exception-button {
      display: none;
    }
    &__mobile-footer {
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      padding: 13px 16px;
      background-color: #fff;
      height: 70px;
      z-index: 6;
      & button {
        width: 100%;
        white-space: nowrap;
        margin-right: 12px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
