@import '../../../styles/variables.scss';

.tv-logo {
  &__img {
    width: 190px;
    outline: none;
    &:focus-visible {
      outline: 1px solid $OUTLINE_COLOR;
      outline-offset: 1px;
    }
  }

  @media (max-width: 1024px) and (orientation: landscape) {
    &__img {
      margin-right: 14px;
      margin-left: -14px;
    }
  }

  &__img-mobile {
    width: 32px;
    height: auto;
  }
}
