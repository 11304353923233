.tv-main_layout {
  &__container {
    display: flex;
    padding: 16px; //#{'max(min(2vw, 32px), 16px)'};
    @supports (display: grid) {
      padding: #{'max(16px, min(1vw, 32px))'} #{'max(16px, min(1vw, 32px))'} 0;
    }
    min-height: calc(100vh - 72px);
    position: relative;
  }
  @media (max-width: 768px) {
    &__container {
      height: calc(100vh - 260px);
      min-height: unset;
      padding: 16px 16px 0px; //#{'max(min(2vw, 32px), 16px)'};

      &-hidden {
        display: none;
      }
    }
  }
}
