@import '../../../styles/variables.scss';

.tv-checkbox-collapse {
  &__container {
  }
  &__content {
    margin-left: 50px;
  }
  &-bng-1 {
    background-color: #ffffff;
  }
  &__header {
    &-checkbox {
      display: flex;
      width: 25%;
    }
    &-press {
      display: flex;
      width: 75%;
    }
  }
  @media (max-width: $breakpoint-tablet) {
    &__header {
      &-checkbox {
        display: flex;
        width: 100%;
        text-overflow: ellipsis;
      }
      &-press {
        display: flex;
        width: 0%;
      }
    }
  }
}
