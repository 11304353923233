@import '../../../styles/variables.scss';

.tv-week-calendar {
  &__message-service-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-left: 10px;
    margin-bottom: 10px;
    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      outline-offset: 1px;
      border-radius: 2px;
    }

    .tooltiptext {
      width: max-content;
      margin-top: 40px;
      visibility: hidden;
      // width: 120px;
      color: white;
      text-align: center;
      border-radius: 4px;
      padding: 5px 10px;
      position: absolute;
      z-index: 1;
      &-Rapporterad {
        // background-color: #b76ed1;
        background-color: $STATUS_REPORTED_COLOR;
      }
      &-Accepterad {
        // background-color: #6478e6;
        background-color: $COLOR_STATUS_ACCEPTED;
      }
      &-Utförd {
        // background-color: #18ad79;
        background-color: $COLOR_STATUS_FULLILLED;
      }
      &-Förfrågan {
        // background-color: #ffa500;
        background-color: $COLOR_STATUS_AVAILABLE;
      }
      &-Avbokad {
        // background-color: #74737b;
        background-color: $COLOR_STATUS_CANCELLED;
      }
    }
    &:hover {
      .tooltiptext {
        visibility: visible;
      }
    }
    &__single {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 5px;
    }
    :active {
      text-decoration: underline;
    }
    &-Rapporterad {
      // color: #b76ed1;
      color: $STATUS_REPORTED_COLOR;
    }
    &-Accepterad {
      // color: #6478e6;
      color: $COLOR_STATUS_ACCEPTED;
    }
    &-Utförd {
      // color: #18ad79;
      color: $COLOR_STATUS_FULLILLED;
    }
    &-Förfrågan {
      // color: #ffa500;
      color: $COLOR_STATUS_AVAILABLE;
    }
    &-Avbokad {
      // color: #74737b;
      color: $COLOR_STATUS_CANCELLED;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
    scroll-behavior: smooth;
    position: relative;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    width: 100%;
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      z-index: 1;
    }
  }
  &__weekdays {
    width: 100%;
    position: relative;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 2;
    scroll-behavior: smooth;
    // Firefox scroll
    -moz-appearance: none !important;
    scrollbar-width: thin;
    scrollbar-color: #bbbbbb;
    // IE Scroll
    scrollbar-face-color: #bbbbbb;
    scrollbar-shadow-color: #ffffff;
    scrollbar-highlight-color: #ffffff;
    // Webkit scrollbar

    .tv-calendar-timeline {
      width: 42px !important;
      @supports (display: grid) {
        width: clamp(42px, 2vw, 80px) !important;
      }
    }

    &::-webkit-scrollbar {
      width: 4px;
      margin-top: 7px;
      position: absolute;
      right: -1px;
    }
    &::-webkit-scrollbar-track {
      background-color: #ffffff;
      margin-top: 34px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #bbbbbb;
      border-radius: 8px;
      height: 154.37px;
      width: 4px;
    }
  }

  &__message-header {
    position: relative;
    height: auto;
    border-top-left-radius: 4px;
    display: flex;
    width: calc(100% - 4px);
    background-color: #fff;
    z-index: 10;
  }
  &__header {
    position: relative;
    height: 34px;
    border-top-left-radius: 4px;
    display: flex;
    width: calc(100% - 4px);
    background-color: #fff;
    text-transform: uppercase;
    z-index: 10;

    &-ie-fix {
      width: calc(100% - 16px) !important;
    }
    &-item {
      width: 100%;
      font-size: 13px;
      padding: 10px 0;
      text-align: center;
      color: #707070;
      line-height: 14px;
      border-right: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      &--today {
        color: $BLUE-10;
      }
    }
  }
  &-filling {
    width: 5vw;
    border-right: 1px solid #e0e0e0;
    min-width: 50px;
    max-width: 79px;
    @supports (display: grid) {
      width: clamp(42px, 5vw, 80px);
    }
  }
  @media (max-width: 768px) {
    &__weekdays {
    }
  }
}

.messeage-service__main-icon {
  display: flex !important;
  justify-content: end !important;
  margin-right: 8px !important;
  color: #707070 !important;
}

.messeage-service-icon {
  font-size: 15px !important;
}
