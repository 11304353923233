@import '../../../styles/variables.scss';

.tv-login-form {
  &__container {
    border: $COMMON_BORDER;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    width: 502px;
    padding: 48px 56px 56px;
    min-height: 414px;
    margin: auto;
  }

  &__header {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 20px;
    /* identical to box height, or 83% */
    text-align: center;
    letter-spacing: 0.2px;
  }
  @media (max-width: $breakpoint-tablet) {
    &__container {
      width: calc(100vw - 32px) !important;
      margin: 16px;
      box-shadow: none;
      border: none;
      padding: 48px 16px 56px;
    }
  }
}
