@import '../../../styles/variables.scss';

.tv-notification-icon {
  &__container {
    height: 30px;
    overflow: hidden;
  }
  &__button {
    border: none;
    outline: none;
    background-color: transparent;

    @media (max-width: $breakpoint-tablet) {
      padding-top: 3px;
      &:focus-visible {
        outline-offset: -6px;
      }
    }
    i {
      color: #74737b;
    }
    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      border-radius: 8px;
      align-self: center;
      outline-offset: -4px;
    }
  }
  &-active {
    color: $BLUE-10 !important;
  }
  &__count {
    width: 16.44px;
    height: 16.44px;
    border: 1.5px solid $BLUE-10;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 19px;
    left: 19px;
    font-size: 10px;
    color: $BLUE-10;
  }
}
