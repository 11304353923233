@import '../../../styles/variables.scss';

.tv-assignment-list {
  &__container {
    .availableRequest-results-empty {
      display: flex;
      justify-content: center;
      text-align: center;
      padding-top: 20px;
      padding-bottom: 10%;
    }
    .tv-assignment {
      &-list-header__item {
        &-r {
          flex-grow: 3;
        }
        &.order-1 {
          padding-left: 15px;
        }
        &.order-4 {
          padding-left: 15px;
        }
      }
      &-item__row {
        &--type {
          flex-grow: 3;
        }
        &--customer {
          width: 12%;
        }
      }
    }
  }
  &-bg-white {
    background-color: white;
  }
  &-title {
    font-weight: 500;
    font-size: 24px;
    color: #252525;
    margin-bottom: 24px;
  }
  &-header {
    display: flex;
    hr {
      margin: 0 15px 0 0;
    }
    &__item {
      flex-grow: 1;
      width: 10%;
      margin-left: 10px;
      font-size: 14px;
      line-height: 18px;
      color: #74737b;
    }
  }
  &__mobile-footer {
    display: none;
  }
  &-empty {
    display: flex;
    width: 100%;
    text-align: center;
    flex-direction: column;
    margin-top: 3%;

    &-img {
      display: block;
      background-size: contain;
      width: 265px;
      height: 150px;
      margin: 10px auto;
    }

    p {
      font-style: normal;
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
    }
    &-para {
      font-style: normal;
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    &__assignment-list {
      padding-bottom: 88px;
    }
    &-header {
      display: none;
    }
    &__mobile-footer {
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      padding: 16px;
      height: 88px;

      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      & button {
        width: 100%;
        height: 48px;
      }
    }
  }
}
.sort-column {
  display: flex;
  align-items: center;
  width: 100%;
}

.sort-icon {
  height: 10px;
  margin-top: 5px;
  width: 10px;
  margin-left: 8px;
  &:focus-visible {
    outline: 2px solid $OUTLINE_COLOR;
    outline-offset: 1px;
    border-radius: 1px;
  }
}
.sort-icon-transform {
  height: 10px;
  margin-bottom: 5px;
  margin-left: 8px;
  width: 10px;
  transform: rotate(180deg);
  &:focus-visible {
    outline: 2px solid $OUTLINE_COLOR;
    outline-offset: 1px;
    border-radius: 1px;
  }
}
